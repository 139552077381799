export enum UserChatType {
  ACCEPT = 'ACCEPTED',
  RESOLVED = 'RESOLVED',
  BLOCKED = 'BLOCKED',
  DEFAULT = 'DEFAULT',
  RECEIVED = 'RECEIVED',
  REJECTED = 'REJECTED',
}

export type ChatBuddyProps = {
  description: string;
  firstName: string;
  largeProfileUrl: string;
  lastMessageTime: string;
  lastMessage: string;
  lastName: string;
  mediumProfileUrl: string;
  programme: string | null;
  publicId: string;
  replyTime: number;
  replyTimeUnit: string;
  smallProfileUrl: string;
  unreadMessageCount: number;
  transferredToAdmin: boolean;
  prospectChannel: string;
  status: UserChatType;
  statusCategory: string | null;
};
