import { Col, Input, Row, Select } from 'antd';
import './add_lead.scss';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import countryList from 'country-list';

const countryNameToCode = countryList.getCodeList();
const countryNames = Object.values(countryNameToCode);

const { Option } = Select;

export const PersonalDetails = ({ handlePersonalDetailInputs }: any) => {
  const handleCountrySelection = (countryName: string) => {
    const countryCode = countryList.getCode(countryName);
    handlePersonalDetailInputs('country', countryCode);
  };

  return (
    <>
      <Row gutter={16} className="personal-detail-first-row">
        <Col span={12} className="personal-detail-name-col">
          <div className="title">Name</div>
          <div>
            <Input
              size="large"
              placeholder="Enter lead name"
              onChange={(e) => handlePersonalDetailInputs('name', e.target.value)}
            />
          </div>
        </Col>
        <Col span={12} className="personal-detail-email-col">
          <div className="title">Email</div>
          <div>
            <Input
              placeholder="Enter lead email address"
              size="large"
              onChange={(e) => handlePersonalDetailInputs('email', e.target.value)}
            />
          </div>
        </Col>
      </Row>
      <Row gutter={16} className="personal-detail-second-row">
        <Col span={12} className="personal-detail-mobile-col ">
          <div>
            <div className="title">Mobile Number</div>
            <div>
              <PhoneInput
                international
                defaultCountry="LK"
                placeholder="Enter lead mobile number"
                size="large"
                onChange={(value) => handlePersonalDetailInputs('mobileNumber', value)}
              />
            </div>
          </div>
        </Col>
        <Col span={12} className="personal-detail-country-col">
          <div className="title">Country</div>
          <div>
            <Select showSearch size="large" placeholder="Select lead country" onChange={handleCountrySelection}>
              {countryNames.map((name) => (
                <Option key={name} value={name}>
                  {name}
                </Option>
              ))}
            </Select>
          </div>
        </Col>
      </Row>
    </>
  );
};
