import { Col, Row, Input, Checkbox } from 'antd';
import './add_lead.scss';
import MultiSelectDropdown from '../../../common/CustomMaltiSelect';

const { TextArea } = Input;

export const CourseDetails = ({ handleCourseDetailInputs, programmes, courseData }: any) => {
  const courseOptions = programmes.reduce((acc: any, programme: any) => {
    const courseOpts = programme.courses.map((course: any) => ({
      label: course.displayName,
      value: course.id,
    }));
    return [...acc, ...courseOpts];
  }, []);

  const handleCourse = (selectedCourse: any) => {
    // console.log('Selected Course Id', selectedCourse);
    handleCourseDetailInputs('courseId', selectedCourse);
  };

  return (
    <>
      <Row className="course-details-programme-row">
        <Col span={12} className="course-details-programme-col">
          <div className="title">Courses interests</div>
          <div>
            <MultiSelectDropdown options={courseOptions} onChange={handleCourse} value={courseData.courseId} />
          </div>
        </Col>
        {/* <Col span={12} className="course-details-course-col">
          <div className="title">Course interests</div>
          <div>
            <Select
              mode="multiple"
              onChange={(value) => handleCourseDetailInputs('courseId', value)}
              placeholder="Select Course"
              className="course-select"
            >
              {programmes.map((programme) => (
                <OptGroup label={programme.displayName} key={programme.id}>
                  {programme.courses.map((course) => (
                    <Option value={course.id} key={course.id}>
                      {course.displayName}
                    </Option>
                  ))}
                </OptGroup>
              ))}
            </Select>
          </div>
        </Col> */}
      </Row>
      <Row className="course-details-note-row">
        <Col span={24} className="course-details-note-col">
          <div className="title">Note to adminstrator</div>
          <div>
            <TextArea
              maxLength={100}
              className="admin-note-area"
              onChange={(e) => handleCourseDetailInputs('note', e.target.value)}
            />
          </div>
        </Col>
      </Row>
      <Row className="course-details-check-row">
        <Col span={24} className="course-details-check-col">
          <Checkbox defaultChecked disabled></Checkbox>
          <span className="invite-text">Send an email invite and initiate the conversation with this lead</span>
        </Col>
      </Row>
      {courseData?.sendEmailInvite ? (
        <Row className="course-details-invite-row">
          <Col span={24} className="course-details-invite-col">
            <div className="title">Enter the message to lead</div>
            <div>
              <TextArea
                maxLength={100}
                className="lead-note-area"
                onChange={(e) => handleCourseDetailInputs('inviteBody', e.target.value)}
              />
            </div>
          </Col>
        </Row>
      ) : (
        <></>
      )}
    </>
  );
};
