import { Layout, Row, Col, Select, Spin, Table, Popover, Button, message } from 'antd';
import React, { useEffect } from 'react';
import 'antd/dist/antd.min.css';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import './style.scss';
import StatTile from './StatTile';
import IconNew from '../../../../src/assets/images/icon-dashboard-new.svg';
import IconMissed from '../../../../src/assets/images/icon-dashboard-missed.svg';
import IconOngoing from '../../../../src/assets/images/icon-dashboard-ongoing.svg';
import { getMyStatDetails, getMyStatsListSelector } from '../LeaderBoardSection/MyStatView/MyStatSlice';
import isMoreThanTwoHoursPassed from '../../../utils/MorethanTwoHour';
import { useSelector } from 'react-redux';
import { getAllBuddyListSelector } from '../../Chat/buddyList/BuddyListSlice';
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  EmailShareButton,
  FacebookIcon,
  WhatsappIcon,
  EmailIcon,
  FacebookMessengerShareButton,
  FacebookMessengerIcon,
} from 'react-share';
import CopyToClipboard from 'react-copy-to-clipboard';
import iconCopy from '../../../../src/assets/images/header/menu-icon-copy.svg';

import { FaFacebookSquare, FaWhatsappSquare } from 'react-icons/fa';

const { Header, Content } = Layout;

const handleChange = (value: string) => {
  console.log(`selected ${value}`);
};

function StatsScreen({ refLink, userName, buddyStatus }) {
  let { buddyList } = useSelector(getAllBuddyListSelector);

  const info = () => {
    message.info('Content copied to clipboard');
  };

  // Get data from redux
  const dispatch = useAppDispatch();
  let { myStatList } = useAppSelector(getMyStatsListSelector);

  useEffect(() => {
    dispatch(getMyStatDetails([]));
  }, []);

  const columns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
    },
    {
      title: 'Prospect name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Date Referred',
      dataIndex: 'joinDate',
      key: 'joinDate',
    },
  ];

  const content = (
    <div>
      <div>
        <WhatsappShareButton url={refLink} title={userName} separator=":: " style={{ display: 'flex' }}>
          <FaWhatsappSquare style={{ color: 'rgb(37 211 102)', marginRight: '2px' }} size={18} /> Whatsapp
        </WhatsappShareButton>
      </div>
      <div>
        <FacebookShareButton url={refLink} quote={userName} style={{ display: 'flex' }}>
          <FaFacebookSquare style={{ color: 'rgb(59,89,152)', marginRight: '2px' }} size={18} /> Facebook
        </FacebookShareButton>
      </div>
      <div>
        <CopyToClipboard
          text={refLink ? refLink : ''}
          onCopy={info}
          style={{ display: 'flex', backgroundColor: 'unset', border: 'none' }}
        >
          <button
            style={{
              backgroundColor: 'unset',
              display: 'flex !important',
              alignItems: 'center !important',
              flexDirection: 'row',
              border: 'none',
            }}
            onClick={() => info}
          >
            {/* <FaCopy className="copyUrlIcon" size={16} /> */}
            <img src={iconCopy} alt="" />
            <div
              style={{
                color: 'black',
                paddingRight: '7px',
              }}
            >
              Copy Url
            </div>
          </button>
        </CopyToClipboard>
      </div>
    </div>
  );

  const commonColProps = { xs: 24, sm: 12, md: 8, lg: 6 };

  return (
    <Content>
      {/* {isFetching ? (
        <Spin spinning={isFetching} />
      ) : ( */}
      <div className="main_content">
        <div className="stat_page">
          <Row className="stat_row_area" gutter={24}>
            <Col {...commonColProps}>
              <StatTile
                btnContent={'View'}
                title={<span className="green">{`${myStatList?.newMessageCount} New`}</span>}
                navigatePage={'/chat?filter=new'}
                subTitle={'New chats'}
                icon={<img src={IconNew} alt="New Icon" className="icon" />}
                toolTip={'New conversation to be attended'}
              />
            </Col>
            <Col {...commonColProps}>
              <StatTile
                btnContent={'View'}
                title={<span className="red">{`${myStatList?.missedMessageCount} Missed`}</span>}
                subTitle={'Within SLA 12hrs'}
                navigatePage={'/chat?filter=missed'}
                icon={<img src={IconMissed} alt="Missed Icon" className="icon" />}
                toolTip={'Conversations not attended within 12hrs'}
              />
            </Col>
            <Col {...commonColProps}>
              <StatTile
                btnContent={'View'}
                title={<span className="orange">{`${myStatList?.ongoingMessageCount} Ongoing`}</span>}
                subTitle={'Ongoing chats'}
                navigatePage={'/chat?filter=ACCEPTED'}
                icon={<img src={IconOngoing} alt="Ongoing Icon" className="icon" />}
                toolTip={'Conversation that are currently on going'}
              />
            </Col>
            <Col {...commonColProps}>
              <StatTile
                btnContent={'View'}
                title={<span className="black">{`${myStatList?.referrals.length} Referred`}</span>}
                subTitle={'My prospect referrals'}
                navigatePage={'open'}
                modal={
                  <Table
                    dataSource={myStatList?.referrals.map((item, index) => ({ index: index + 1, ...item }))}
                    columns={columns}
                    pagination={false}
                  />
                }
                icon={
                  <>
                    {buddyStatus === 'ACTIVE' ? (
                      <Popover
                        placement="bottom"
                        content={content}
                        trigger="click"
                        showArrow={false}
                        className="share_select"
                        style={{
                          borderRadius: '10px !important',
                        }}
                      >
                        <Button
                          className="shareButtonCustom"
                          style={{
                            height: '25px',
                            textAlign: 'center',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            zIndex: 10,
                          }}
                          onClick={(event) => event.stopPropagation()}
                        >
                          Share
                        </Button>
                      </Popover>
                    ) : (
                      <img src={IconOngoing} alt="Icon Ongoing" className="icon" />
                    )}
                  </>
                }
                toolTip={'Number of prospects that have inquired on Univiser using your unique referral link'}
              />
            </Col>
          </Row>
        </div>
      </div>
      {/* )} */}
    </Content>
  );
}

export default StatsScreen;
