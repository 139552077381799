import { Card, Layout, Spin, Steps } from 'antd';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import CoverComponent from '../../../../components/CoverComponent/coverComponentV2';
import CommonForm from '../../../../components/form/CommonForm';
import MainHeader from '../../../../layout/Header/MainHeader';
import { RegisterBuddyProps } from '../../../../Models/RegisterBuddyProps';
import { createPasswordSelector, fetchingUserRequest } from '../../../createNewPassword/CreatePasswordSlice';
import { uploadImagesSelector } from '../../../ImageUpload/ImageUploadSlice';
import { checkMobile } from '../../mobile/CheckMobileSlice';
import formDetails from '../../student-reg.json';
import alumniFormDetails from '../../alumni-reg.json';
import staffFormDetails from '../../staff-reg.json';
import parentFormDetails from '../../parent-reg.json';
import { registerRequest } from '../RegisterSlice';
import '../style.scss';
import { verifyMobile, verifyMobileResponseSelector } from '../../mobile/VerifyMobileSlice';
import OTPForm from '../../OTPForm';
import { getPublicUniDetails, publicUniDataSelector } from '../../publicUniDetails/publicUniDetailsSlice';
import { useLocalStorage } from '../../../../hooks/localStoragehook/LocalStorageHook';
import { RegistrationForm } from './RegistrationForm';
import moment from 'moment';

const { Header, Content } = Layout;

const { Step } = Steps;

export const RegistrationRevamp = () => {
  const [current, setCurrent] = useState(0);
  let navigate = useNavigate();
  const dispatch = useAppDispatch();

  // Redux Selectors
  let { status, isFetching, uniPublicData } = useAppSelector(publicUniDataSelector);

  let { coverPhotoUploadStatus, profilePhotoStatus, coverPhotoUrl, profilePhotoUrl } =
    useAppSelector(uploadImagesSelector);

  let { dataIsFetching, fetchingDataStatus, userData, createdPassword, isPasswordCreatedSuccessfully } =
    useAppSelector(createPasswordSelector);

  let { isVerifyMobileFetching, verifyMobileResponse, verifyMobileStatus } =
    useAppSelector(verifyMobileResponseSelector);

  let page = 'registration';

  const [formState, setFormState] = useState<any>();
  const [registerFormValues, setRegisterFormValues] = useState<RegisterBuddyProps>();
  const [onBoardData, setOnBoardData] = useLocalStorage('Onboard', null);
  const [openSucessModal, setOpenSuccessModal] = useState<boolean>(false);
  const [regAvatar, setRegAvatar] = useState('');
  const [regCover, setRegCover] = useState('');
  const [programmeData, setProgrammeData] = useState<any>([]);
  const [yearOfStudy, setYearOfStudy] = useState<any>([]);
  const [interestsData, setInterestsData] = useState<any>([]);
  const [languagesData, setLanguagesData] = useState<any>([]);
  const [countryData, setCountryData] = useState<any>([]);
  const [formData, setFormData] = useState<any>({});
  const [phoneCode, setPhoneCode] = useState(null);
  const [subdomain, setSubdomain] = useState('');

  const [errorObj, setErrorObj] = useState<any>({});
  const [whatsappChecked, setWhatsappChecked] = useState(true);
  const [registrationRequest, setRegistrationRequest] = useState(false);

  useEffect(() => {
    if (registrationRequest || verifyMobileStatus === 'SUCCESS') {
      let obj = {
        ...formData,
        publicId: onBoardData?.publicId,
        password: onBoardData?.password,
        coverPhotoUrl: regCover,
        profilePhotoUrl: regAvatar,
      };

      if (whatsappChecked) {
        obj['whatsapp'] = formData?.mobileNumber;
      }

      dispatch(registerRequest(obj!));
    }
  }, [registrationRequest, verifyMobileStatus]);

  useEffect(() => {
    dispatch(getPublicUniDetails(onBoardData?.publicId));
    const fetchCountryCode = async () => {
      try {
        const response = await fetch(`https://ipapi.co/json/`);
        const data = await response.json();
        setPhoneCode(data.country_code);
      } catch (error) {
        console.error('Error fetching IP information:', error);
      }
    };

    fetchCountryCode();
  }, []);

  useEffect(() => {
    dispatch(fetchingUserRequest(onBoardData?.publicId!));
  }, []);

  useEffect(() => {
    setFormState(uniPublicData);

    if (uniPublicData?.programmes) {
      const filterdProgrammes = manageSelectArr(uniPublicData?.programmes, 'programmes');
      setProgrammeData(filterdProgrammes);
    }

    if (uniPublicData?.years) {
      const filteredYears = manageSelectArr(uniPublicData?.years, 'years');
      setYearOfStudy(filteredYears);
    }

    if (uniPublicData?.interests) {
      const filteredInterests = manageSelectArr(uniPublicData?.interests, 'interests');
      setInterestsData(filteredInterests);
    }

    if (uniPublicData?.clientLanguages) {
      const filteredLanguages = manageSelectArr(uniPublicData?.clientLanguages, 'languages');
      setLanguagesData(filteredLanguages);
    }

    if (uniPublicData?.countries) {
      const filteredCountries = manageSelectArr(uniPublicData?.countries, 'countries');
      setCountryData(filteredCountries);
    }

    if (uniPublicData) {
      const currentURL: any = uniPublicData && uniPublicData?.domain;

      // Split the URL by dots
      const parts = currentURL && currentURL?.split('.');
      // Extract the first part (subdomain)
      const extractedSubdomain = !!parts && parts?.length > 0 && parts[0];
      // Update state with the extracted subdomain
      setSubdomain(extractedSubdomain);
    }
  }, [uniPublicData]);

  const manageSelectArr = (arr: any, checkFor: any) => {
    let managedArr: any = [];

    for (let index = 0; index < arr.length; index++) {
      const element = arr[index];

      let obj = {
        value: checkFor === 'countries' ? element?.code : element?.id ?? element?.code,
        label: element?.name,
      };

      if (element?.iconUrl) {
        obj['iconUrl'] = element?.iconUrl;
      }
      managedArr.push(obj);
    }

    return managedArr;
  };

  const onChangeForm = (key: any, value: any) => {
    if (key === 'yearOfGraduation' && moment.isMoment(value)) {
      setFormData((prevData: any) => ({
        ...prevData,
        [key]: value.format('YYYY'),
      }));
    } else {
      setFormData((prevData: any) => ({
        ...prevData,
        [key]: value,
      }));
    }
  };

  useEffect(() => {
    if (current === 2) {
      dispatch(
        checkMobile({
          publicId: onBoardData?.publicId || '',
          mobileNo: formData?.mobileNumber || '',
        })
      );
    } else if (current === 3) {
      dispatch(
        verifyMobile({
          publicId: onBoardData?.publicId || '',
          otp: formData?.otpCode || '',
        })
      );
    }
  }, [current]);

  useEffect(() => {
    if (verifyMobileStatus === 'SUCCESS') {
      setOpenSuccessModal(true);
    } else if (verifyMobileStatus === 'FAILED') {
      setCurrent(2);
    }
  }, [verifyMobileStatus]);

  const handleImageUploads = (key: string, value: string) => {
    if (key === 'cover') {
      setRegCover(value);
    }

    if (key === 'profile') {
      setRegAvatar(value);
    }
  };

  const renderFieldObject = (current: number) => {
    if (userData?.type === 'STUDENT') {
      return formDetails.steps[current];
    } else if (userData?.type === 'ALUMNI') {
      return alumniFormDetails.steps[current];
    } else if (userData?.type === 'STAFF') {
      return staffFormDetails.steps[current];
    } else if (userData?.type === 'PARENT') {
      return parentFormDetails.steps[current];
    }
  };

  //   console.log("=== formData ===", formData);
  // console.log('===UniPublicData===', uniPublicData);

  return (
    <>
      <Header className="header">
        <MainHeader disabled={true} />
        {/* <CoverComponent/> */}
      </Header>
      <CoverComponent
        page={page}
        onBoardData={onBoardData}
        uniPublicData={uniPublicData}
        handleImageUploads={handleImageUploads}
      />
      <Content className="main_content">
        {/* <Steps current={current} className="step_area">
          {formDetails.steps.map((item) => (
            <Step key={item.title} title={item.title} />
          ))}
        </Steps> */}
        <Steps current={current} className="step_area">
          {formDetails.steps.map((item) => {
            if (
              item.title !== 'Verification & Approval' ||
              (uniPublicData?.univeristyConfig?.enabledBuddyOnboardOtpVerification &&
                item.title === 'Verification & Approval')
            ) {
              return <Step key={item.title} title={item.title} />;
            }
            return null;
          })}
        </Steps>
        <div className="register">
          <Card className="card">
            <RegistrationForm
              formSchema={renderFieldObject(current)}
              data={formState}
              loading={isVerifyMobileFetching}
              setCurrent={setCurrent}
              current={current}
              regAvatar={regAvatar}
              userData={userData}
              programmeData={programmeData}
              yearOfStudy={yearOfStudy}
              onChangeForm={onChangeForm}
              interestsData={interestsData}
              languagesData={languagesData}
              countryData={countryData}
              registerFormValues={registerFormValues}
              uniPublicData={uniPublicData}
              formData={formData}
              onBoardData={onBoardData}
              phoneCode={phoneCode}
              subdomain={subdomain}
              whatsappChecked={whatsappChecked}
              setWhatsappChecked={setWhatsappChecked}
              setRegistrationRequest={setRegistrationRequest}
            />
          </Card>
        </div>
      </Content>
    </>
  );
};
