import { Button, Row, Typography, message } from 'antd';
import { useState } from 'react';
import { PersonalInfo } from './PersonalInfo';
import { ProfessionalInfo } from './ProfessionalInfo';
import OTPForm from '../../OTPForm';
import { personalInfoValidation, professionInfoValidation } from './validation';

export const RegistrationForm = ({
  userData,
  formSchema,
  data,
  setCurrent,
  current,
  onChange,
  loading,
  regAvatar,
  programmeData,
  yearOfStudy,
  onChangeForm,
  interestsData,
  languagesData,
  countryData,
  registerFormValues,
  uniPublicData,
  formData,
  onBoardData,
  phoneCode,
  subdomain,
  whatsappChecked,
  setWhatsappChecked,
  setRegistrationRequest,
}: any) => {
  const [formState, setFormState] = useState({});
  const [errorObject, setErrorObject] = useState({});

  const removeError = (key: any) => {
    // Check if the key exists in the object
    if (errorObject.hasOwnProperty(key)) {
      // Delete the specified key from the original object
      delete errorObject[key];
    }
    // You can optionally return the modified object
    return errorObject;
  };

  const handleRegister = () => {
    const validationResult: any = professionInfoValidation(formData, userData?.type);
    setErrorObject(validationResult);

    if (Object.keys(validationResult).length === 0) {
      setRegistrationRequest(true);
    }
  };

  const next = () => {
    if (current === 0 && regAvatar === '') {
      message.error('Profile Image is Required !');
      return;
    } else if (current === 0) {
      const validationResult: any = personalInfoValidation(formData);
      setErrorObject(validationResult);

      if (Object.keys(validationResult).length === 0) {
        setCurrent(Math.max(current + 1, 0));
      }
    } else if (current === 1) {
      const validationResult: any = professionInfoValidation(formData, userData?.type);
      setErrorObject(validationResult);

      if (Object.keys(validationResult).length === 0) {
        setCurrent(Math.max(current + 1, 0));
      }
    }
  };

  const previous = () => setCurrent(Math.min(current - 1, 0));

  const renderSection = () => {
    switch (current) {
      case 0:
        return (
          <PersonalInfo
            data={data}
            userData={userData}
            onChangeForm={onChangeForm}
            interestsData={interestsData}
            languagesData={languagesData}
            countryData={countryData}
            errorObject={errorObject}
            removeError={removeError}
            phoneCode={phoneCode}
            subdomain={subdomain}
            whatsappChecked={whatsappChecked}
            setWhatsappChecked={setWhatsappChecked}
            formData={formData}
          />
        );
        break;

      case 1:
        return (
          <ProfessionalInfo
            data={data}
            programmeData={programmeData}
            yearOfStudy={yearOfStudy}
            userData={userData}
            onChangeForm={onChangeForm}
            errorObject={errorObject}
            removeError={removeError}
            uniPublicData={uniPublicData}
            formData={formData}
          />
        );

      case 2:
        return (
          <OTPForm
            setCurrent={setCurrent}
            publicId={onBoardData?.publicId || ''}
            mobileNo={formData?.mobileNumber || ''}
            uniPublicData={uniPublicData}
            formData={formData}
          />
        );

      default:
        break;
    }
  };

  return (
    <>
      {renderSection()}
      <div className="form_btn_area">
        <Typography className="form_edit_text">
          You can edit your profile at any time and your Personal Information will not be shared with prospects
        </Typography>
        {current === 0 || current === 1 ? (
          <div className="btn_area">
            {current > 0 && (
              <Button loading={loading} onClick={previous} style={{ marginRight: '3px' }}>
                Go back
              </Button>
            )}
            {current === 0 ? (
              <Button loading={loading} onClick={next} type="primary">
                Next
              </Button>
            ) : uniPublicData?.univeristyConfig?.enabledBuddyOnboardOtpVerification ? (
              <Button loading={loading} onClick={next} type="primary">
                Next
              </Button>
            ) : (
              <Button loading={loading} onClick={handleRegister} type="primary">
                Register
              </Button>
            )}
          </div>
        ) : (
          <></>
        )}
      </div>
    </>
  );
};
