import { Avatar, Button, Empty, Image, message, Popover, Spin, Upload } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import 'antd/dist/antd.min.css';
import { RiImageEditLine, RiPencilFill } from 'react-icons/ri';
import { MdModeEdit } from 'react-icons/md';
import '../style.scss';
import cover from '../../assets/images/cover.png';
import IconStudent from '../../assets/images/icon-student.svg';
import avatar_default from '../../assets/images/user-default.png';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import {
  fetchInformationSelector,
  fetchPersonalInformationRequest,
} from '../../screens/profile/personalInformation/PersonalInformationSlice';
import { updateCoverPhotoSelector, updateCoverRequest } from '../../screens/ImageUpload/CoverPhotoUpdateSlice';
import { useLocalStorage } from '../../hooks/localStoragehook/LocalStorageHook';
import toCamelCase from '../../utils/stringFormat/CapsTocamel';
import { AuthPropType, useAuth } from '../AuthProvider/AuthoProvider';
import { useLocation } from 'react-router-dom';
import uploadFileToBlob, { isStorageConfigured } from '../../utils/azure/azure';
import {
  clearIntroVideoUpdateResponse,
  updateIntroVideoRequest,
  updateIntroVideoSelector,
} from '../../screens/profile/personalInformation/UploadIntroVideoSlice';
import { AiOutlineMore } from 'react-icons/ai';
import { IntroVideoPreviewModal } from '../IntroVideoPreviewModal/IntroVideoPreviewModal';
import { fetchUniDataSelector } from '../../screens/register/uniData/UniDataFetchingSlice';

const storageConfigured = isStorageConfigured();

interface OnBoardingProps {
  buddyName: string;
  email: string;
  password: string;
  publicId: string;
}

interface CoverComponentProps {
  page?: any;
  onBoardData?: any;
  uniPublicData?: any;
  handleImageUploads?: (key: string, value: string) => void;
}

function CoverComponent({ page = '', onBoardData, uniPublicData, handleImageUploads }: CoverComponentProps) {
  let { informationResponse } = useAppSelector(fetchInformationSelector);
  let { isUpdatingCover, coverUpdatedStatus } = useAppSelector(updateCoverPhotoSelector);
  let { isUpdatingIntroVideo, updateIntroVideoStatus } = useAppSelector(updateIntroVideoSelector);
  let { uniDataStatus, uniDataIsFetching, uniDataResponse } = useAppSelector(fetchUniDataSelector);

  const fileInputRef = useRef<any>(null);

  let dispatch = useAppDispatch();
  let location = useLocation();
  const [file, setFile] = useState(null);
  const [userData, setUserData] = useLocalStorage('user', null);
  let { user }: AuthPropType = useAuth();
  const [uploading, setUploading] = useState(false);
  const [regAvatar, setRegAvatar] = useState('');
  const [regCover, setRegCover] = useState('');

  // all blobs in container
  const [blobList, setBlobList] = useState('');
  // current file to upload into container
  const [fileSelected, setFileSelected] = useState(null);
  const [inputKey, setInputKey] = useState(Math.random().toString(36));

  const [error, setError] = useState('');

  const [visibleVideModal, setVisibleVideoModal] = useState<boolean>(false);

  useEffect(() => {
    const formData = new FormData();
    // @ts-ignore
    if (file) {
      formData.append('file', file);
    }
  }, [file]);

  useEffect(() => {
    if (coverUpdatedStatus === 'SUCCESS') {
      dispatch(fetchPersonalInformationRequest());
    }
  }, [coverUpdatedStatus]);

  useEffect(() => {
    if (updateIntroVideoStatus === 'SUCCESS') {
      message.success('Intro Video updated!');
      dispatch(fetchPersonalInformationRequest());
      clearIntroVideoUpdateResponse();
    }
  }, [updateIntroVideoStatus]);

  const handleChangeCoverPhoto = (info) => {
    if (info.file.status === 'uploading') {
      setUploading(true); // Set uploading state to true while file is being uploaded
    } else {
      setUploading(false); // Set uploading state to false after upload is completed or failed
    }

    if (info.file.status === 'done') {
      if (page === 'registration') {
        setRegCover(`${info.file.response?.basePath}/${info.file.response?.fileName}`);
        if (handleImageUploads) {
          handleImageUploads('cover', `${info.file.response?.basePath}/${info.file.response?.fileName}`);
        }
      }
      message.success('Cover Photo updated!');
      dispatch(
        updateCoverRequest({
          coverImageUrl: `${info.file.response?.basePath}/${info.file.response?.fileName}`,
        })
      );
    }
  };

  const handleChangeAvatarPhoto = (info) => {
    if (info.file.status === 'uploading') {
      setUploading(true); // Set uploading state to true while file is being uploaded
    } else {
      setUploading(false); // Set uploading state to false after upload is completed or failed
    }

    if (info.file.status === 'done') {
      message.success('Profile photo updated!');
      if (page === 'registration') {
        setRegAvatar(`${info.file.response?.basePath}/${info.file.response?.fileName}`);
        if (handleImageUploads) {
          handleImageUploads('profile', `${info.file.response?.fileName}`);
        }
      }
      dispatch(
        updateCoverRequest({
          avatarIconUrl: `${info.file.response?.fileName}`,
        })
      );
    }
  };

  const beforeUpload = (file) => {
    const fileSizeLimit = 1; // 1 MB
    const fileSizeInMB = file.size / 1024 / 1024;
    if (fileSizeInMB > fileSizeLimit) {
      message.error('File size exceeds the limit of 1 MB');
      return false; // Prevent file upload
    }
    const isImage = file.type.startsWith('image/');
    if (!isImage) {
      console.log('You can only upload image files!');
    }
    return isImage;

    return true; // Allow file upload
  };

  const handleUploadHeader = () => {
    let obj = {};
    if (page === 'registration') {
      obj = {
        uniId: process.env.REACT_APP_BASE_ID as string,
      };
    } else {
      obj = {
        Authorization: `Bearer ${userData?.access_token}`,
      };
    }
    return obj;
  };

  const handleUploadAction = () => {
    let action = '';

    if (page === 'registration') {
      action = `${process.env.REACT_APP_API_URL}/buddy/api/public/${onBoardData?.publicId}/upload`;
    } else {
      action = `${process.env.REACT_APP_API_URL}/buddy/api/user/upload`;
    }

    return action;
  };

  const handleUploadButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef?.current?.click(); // Trigger the hidden file input when the button is clicked
    }
  };

  const handleFileChange = async (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      let fileNameSplitted = selectedFile?.name?.split('.');

      const videoFileName = `${fileNameSplitted[0]}-${selectedFile?.lastModified}.${fileNameSplitted[1]}`;

      const newFile = new File([selectedFile], videoFileName, { type: selectedFile.type });

      setFileSelected(selectedFile);

      // Check file size (in bytes)
      const maxSize = 50 * 1024 * 1024; // 50MB
      if (selectedFile.size > maxSize) {
        // setError("Video size must be less than 50MB.");
        message.error('Video size must be less than 50MB.');
        return;
      }

      // Create a video element to get the duration
      const video = document.createElement('video');
      video.preload = 'metadata';
      video.onloadedmetadata = async () => {
        // Check video duration (in seconds)
        const durationInSeconds = video.duration;
        if (durationInSeconds > 60) {
          // setError("Video duration must be less than or equal to 1 minute.");
          message.error('Video duration must be less than or equal to 1 minute.');
          video.remove(); // Clean up the video element
          setUploading(false);
          return;
        }

        // prepare UI
        setUploading(true);

        // *** UPLOAD TO AZURE STORAGE ***
        const blobsInContainer: any = await uploadFileToBlob(newFile, 'videos');

        if (blobsInContainer) {
          dispatch(updateIntroVideoRequest({ introUrl: blobsInContainer }));
        }

        // prepare UI for results
        setBlobList(blobsInContainer);

        // Clean up the video element
        video.remove();

        // reset state/form
        setFileSelected(null);
        setUploading(false);
        setInputKey(Math.random().toString(36));
      };
      // Load the selected video file into the video element
      video.src = URL.createObjectURL(selectedFile);
    }
  };

  const PreviewVideoButton = () => {
    return (
      <Button type="ghost" onClick={handleVideoModal}>
        Preview Video
      </Button>
    );
  };

  const handleVideoModal = () => {
    setVisibleVideoModal(true);
  };

  const handleVideoModalCancel = () => {
    setVisibleVideoModal(false);
  };

  // console.log('===uniDataResponse===', uniDataResponse);
  // console.log('+++uniPublicData Video+++', uniPublicData?.univeristyConfig?.buddyVideoEnable);

  return (
    <div className="cover_component">
      <IntroVideoPreviewModal
        visibleVideModal={visibleVideModal}
        handleVideoModalCancel={handleVideoModalCancel}
        introVideoUrl={informationResponse?.profile?.introVideo}
      />
      <div className="cover_image_wrapper">
        {/* style={{backgroundImage: `url(${background})`}} */}
        {isUpdatingCover && (
          <Spin tip="Loading" size="large">
            <div className="content" />
          </Spin>
        )}

        {regCover ||
        (!!informationResponse && informationResponse?.profile && informationResponse?.profile.banner?.imageUrl) ? (
          <img
            src={regCover ? regCover : `${informationResponse?.profile.banner?.imageUrl}`}
            alt=""
            className="cover_image"
          />
        ) : (
          <Empty
            image={cover}
            className="cover_image"
            description={'No Cover Photo Uploaded'}
            style={{ backgroundColor: '#2d3436', color: '#fafafa' }}
          />
        )}

        <div className="avatar_wrapper">
          <Avatar
            size="large"
            className="avatar"
            src={
              <Image
                preview={false}
                src={
                  regAvatar
                    ? regAvatar
                    : (informationResponse?.profile && informationResponse.profile.largeProfileUrl) || avatar_default
                }
              />
            }
          />
          <>
            {' '}
            {/* {(user || page == 'registration') && (
              <div className="name-and-badge">
                <h2>{!!informationResponse?.profile?.firstName}</h2>

                 <div className={"badge"}>
                 <span>{toCamelCase(informationResponse?.profile.type ?? "")}</span>
                 <FaUserGraduate style={{marginLeft:5}}/>
                 <img src={IconStudent} className="icon_type" />
                </div>
              </div>
            )} */}
            {user && page !== 'registration' && (
              <div className="name-and-badge">
                <h2>{informationResponse?.profile?.displayName}</h2>

                <div className={'badge'}>
                  <span>{toCamelCase(informationResponse?.profile.type ?? '')}</span>
                  <img src={IconStudent} className="icon_type" />
                </div>
                <div className="status-indicator">
                  {informationResponse?.status === 'PENDING' && (
                    <>
                      <div className="status-circle" style={{ backgroundColor: '#FC9403' }} />
                      <span style={{ color: 'white' }}>Your account is under review</span>
                    </>
                  )}
                  {/* {informationResponse?.status === 'ACTIVE' && (
                    <>
                      <div className="status-circle" style={{ backgroundColor: '#019C58' }} />
                      <span style={{ color: 'white' }}>You are online</span>
                    </>
                  )} */}
                  {informationResponse?.status === 'OFFLINE' && (
                    <>
                      <div className="status-circle" style={{ backgroundColor: '#DB4E4E' }} />
                      <span style={{ color: 'white' }}>You are offline</span>
                    </>
                  )}
                </div>
              </div>
            )}
            <Upload
              className="upload_area"
              onChange={handleChangeAvatarPhoto}
              accept="image/*"
              beforeUpload={beforeUpload} // Add beforeUpload prop for validation
              headers={handleUploadHeader()}
              action={handleUploadAction()}
            >
              {uploading ? (
                <Spin spinning={uploading} />
              ) : (
                <>
                  {' '}
                  {(user || page === 'registration') && (
                    <Button className="change_avatar_btn_sml" loading={uploading}>
                      <RiPencilFill />
                    </Button>
                  )}
                </>
              )}
            </Upload>
          </>
        </div>
        {(user || page == 'registration') && (
          <Upload
            className="upload_area"
            onChange={handleChangeCoverPhoto}
            beforeUpload={beforeUpload} // Add beforeUpload prop for validation
            accept="image/*"
            headers={handleUploadHeader()}
            action={handleUploadAction()}
          >
            <Button
              className="change_cover_btn"
              style={{
                right:
                  (uniDataResponse && uniDataResponse?.univeristyConfig?.buddyVideoEnable) ||
                  (uniPublicData && uniPublicData?.univeristyConfig?.buddyVideoEnable)
                    ? '250px'
                    : '90px',
              }}
            >
              {/* <RiImageEditLine /> */}
              <MdModeEdit style={{ color: 'white', marginRight: '5px' }} />
              Change
            </Button>
          </Upload>
        )}
        {(uniDataResponse && uniDataResponse?.univeristyConfig?.buddyVideoEnable) ||
        (uniPublicData && uniPublicData?.univeristyConfig?.buddyVideoEnable) ? (
          <>
            {' '}
            <input
              type="file"
              accept="video/*"
              style={{ display: 'none' }}
              ref={fileInputRef}
              onChange={handleFileChange}
              // onChange={onFileChange}
              key={inputKey || ''}
            />
            <Button className="change_cover_btn_2" id="coverPhotoButton" onClick={handleUploadButtonClick}>
              {isUpdatingIntroVideo ? 'Uploading' : 'Upload Intro Video'}
            </Button>
            {informationResponse && informationResponse?.profile?.introVideo && (
              <Popover className="preview_button" placement="bottom" title={null} content={<PreviewVideoButton />}>
                <Button icon={<AiOutlineMore />} />
              </Popover>
            )}
          </>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}

export default CoverComponent;
